import React, {FC, useEffect, useState} from 'react'
import {useForm, SubmitHandler} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'
import {IAuth, ILogin} from '../../redux/login/loginInterfaces'
import {Button, TextField} from '@mui/material'
import {useDispatch} from 'react-redux'
import {login, setAADLoginStatus} from '../../redux/login/loginActions'
import './Login.scss'
import {CloudCircle} from '@mui/icons-material'
import {useMsal, useIsAuthenticated} from '@azure/msal-react'
import {loginRequest, tokenRequest} from '../auth/msalConfig'
import {AccountInfo, AuthError, ClientAuthError, InteractionRequiredAuthError, InteractionStatus, ServerError} from '@azure/msal-browser'
import {isTokenExpired, isAuthorized, getAuthToken} from '../../shared/utils/utilities'
import userService from '../../services/userService'
import {Me} from '../../redux/user/interfaces'
import jwtDecode from 'jwt-decode'
const LoginForm: FC = (): any => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {instance, accounts, inProgress} = useMsal()

  const {register, handleSubmit} = useForm<ILogin>()
  const [attempts, setAttempts] = useState<number>(0)
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const [useAzureAuth, setUseAzureAuth] = useState<boolean>(true)
  // const activeAccount = instance.getActiveAccount()
  const isAuthenticated = useIsAuthenticated()

  interface JwtPayload {
    exp: number
    [key: string]: any
  }

  useEffect(() => setUseAzureAuth(true), [])

  useEffect(() => {
    dispatch(setAADLoginStatus(null))
    if (!useAzureAuth) return

    if (
      !isAuthenticated ||
      inProgress !== InteractionStatus.None ||
      accounts?.length === 0
    )
      return

    const initializeToken = async () => {
      let accessToken: string | null = null

      try {
        // Await the result of handleRedirectPromise
        const response = await instance.handleRedirectPromise()
        if (response) {
          instance.setActiveAccount(response.account)
          accessToken = response.accessToken
        }
      } catch (error) {
        console.error("Error during redirect handling:", error)
      }

      if (!accessToken) {
        try {
          await instance.acquireTokenRedirect(tokenRequest)
        } catch (error) {
          console.error("Redirect token acquisition failed.", error)
        }
      }
      
      if (isAuthenticated && accessToken) {
        await acquireToken()
      }
    }
    initializeToken()
  }, [isAuthenticated, inProgress, instance, dispatch]);

  const handleTokenResponse = async (tokenResponse: any) => {
    const expiresOn = new Date(tokenResponse.expiresOn ?? new Date())

    const auth : IAuth = {
      id: parseInt(tokenResponse.uniqueId),
      isLoggedIn: tokenResponse.account !== null,
      refreshToken: '',
      expires: expiresOn,
      role: '',
      token: '',
      permissions: [],
      userName: tokenResponse.account?.username,
      isAAD: true,
    }

    localStorage.setItem('auth', JSON.stringify(auth))

    const me: Me = await userService.getMe()
    if (!me) {
      dispatch(setAADLoginStatus('Inloggat konto saknar åtkomst.'))
      return
    }

    auth.permissions = me.permissions
    auth.role = me.role
    localStorage.setItem('auth', JSON.stringify(auth))


    navigate('/home')
  }

  const handleTokenError = (err: any) => {
    if (err instanceof InteractionRequiredAuthError) {
      dispatch(setAADLoginStatus('Interaction required to obtain token: ' + err.message))
    } else if (err instanceof ClientAuthError) {
      dispatch(setAADLoginStatus('Client authentication error: ' + err.message))
    } else if (err instanceof ServerError) {
      dispatch(setAADLoginStatus('Server error: ' + err.message))
    } else if (err?.message) {
      dispatch(setAADLoginStatus('An unknown error occurred while acquiring access token: ' + err.message))
      console.log(err)
    } else {
      dispatch(setAADLoginStatus('An unknown error occurred while acquiring access token.'))
      console.log(err)
    }
  }
  const acquireToken = async () => {

    let activeAccount = instance.getActiveAccount()
    if (!activeAccount && accounts.length > 0) {
      instance.setActiveAccount(accounts[0])
      activeAccount = instance.getActiveAccount()
    }

    if (!activeAccount) {
      dispatch(setAADLoginStatus('No active account found. Please log in.'))
      setIsDisabled(false)
      return
    }

    const myTokenRequest = {
      scopes: tokenRequest.scopes,
      account: activeAccount
    }

    let aquireTokenFailed = false

    try {
      const tokenResponse = await instance.acquireTokenSilent(myTokenRequest)
      await handleTokenResponse(tokenResponse)
    } catch (err) {
      handleTokenError(err)
      aquireTokenFailed = true
    }

    // If aquire token failed, attempt it with the next account in the accounts array.
    if (aquireTokenFailed) {
      for (let i = 1; i < accounts.length; i++) {
        myTokenRequest.account = accounts[i]
        try {
          const tokenResponse = await instance.acquireTokenSilent(myTokenRequest)
          await handleTokenResponse(tokenResponse)
          break
        } catch (err) {
          handleTokenError(err)
        }
      }
    }
  }

  const onSubmit: SubmitHandler<ILogin> = async (data) => {
    setIsDisabled(true)
    setUseAzureAuth(false)
    dispatch(login(data))
    setTimeout(() => {
      setAttempts(attempts + 1)
      setIsDisabled(false)
    }, attempts * 1000)
  }

  const onAzureLogin = async () => {
    setIsDisabled(true)
    try {
      await instance.loginRedirect(loginRequest)
    } catch (err) {
      if (err instanceof ClientAuthError) {
        dispatch(setAADLoginStatus(`Client error: ${err.message}`))
      } else if (err instanceof ServerError) {
        dispatch(setAADLoginStatus(`Server error: ${err.message}`))
      } else if (err instanceof InteractionRequiredAuthError) {
        dispatch(setAADLoginStatus(`Interaction required: ${err.message}`))
      } else if (err instanceof AuthError) {
        dispatch(setAADLoginStatus(`Authentication error: ${err.message}`))
      } else {
        dispatch(setAADLoginStatus('An unknown error occurred during login redirect.'))
      }
      setIsDisabled(false)
    }
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField margin='normal' required fullWidth label='Användarnamn' {...register('username', {required: true})} />
      <TextField margin='normal' required fullWidth label='Lösenord' type='password' {...register('password', {required: true})} />
      <Button type='submit' fullWidth variant='contained' sx={{mt: 3, mb: 2}} disabled={isDisabled} style={{backgroundColor: '#00d170'}}>
        Logga in
      </Button>
      <Button
        fullWidth
        variant='contained'
        sx={{mt: 3, mb: 2}}
        disabled={isDisabled}
        style={{backgroundColor: '#007FFF', color: 'white'}}
        startIcon={<CloudCircle fontSize='small' />}
        onClick={onAzureLogin}>
        Logga in med Microsoft Entra ID
      </Button>
    </form>
  )
}
export default LoginForm
