import {FC, useState} from 'react'
import {Grid} from '@mui/material'
import PreBreadcrumbs from '../../../components/shared/PreBreadcrumbs'
import Sidebar from './Sidebar'
import Header from './Header'
import {MainWrapper, MainContent, Pagetitle} from './SidebarLayoutStyle'
import {Outlet} from 'react-router'
import {Navigate} from 'react-router-dom'

const SidebarLayout: FC = ({children}): JSX.Element => {
  const auth = JSON.parse(localStorage.getItem('auth')!)
  let isLoggedIn = auth && Date.now() <= Date.parse(auth.expires)
  const [sidebarWidth, setSidebarWidth] = useState(300)

  if (!isLoggedIn) return <Navigate to='/login' />

  return (
    <>
      <Header />
      <div style={{ display: 'flex', height: '100%' }}>
        <Sidebar sidebarWidth={sidebarWidth} setSidebarWidth={setSidebarWidth} />
        <MainWrapper>
          <MainContent>
            <Pagetitle>
              <Grid container={true} justifyContent='space-between' alignItems='center'>
                <Grid item={true}>
                  <PreBreadcrumbs />
                </Grid>
                <Grid item={true}>
                  {/* <Button sx={{mt: {xs: 2, md: 0}}} variant='contained' startIcon={<AddTwoToneIcon fontSize='small' />}>
                    Create transaction
                  </Button> */}
                </Grid>
              </Grid>
            </Pagetitle>
            <div>
              <Outlet />
            </div>
          </MainContent>
        </MainWrapper>
      </div>
    </>
  )
}

export default SidebarLayout